body {
  margin: 0;
  padding: 0;
  /*font-family: sans-serif;*/
}

.App {
  width   : 100%;
  height  : 100vh;
  touch-action: none;
}

.controls {
  display: none;
  position: absolute;
  width: 20%;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: #222;
}

.productoption {
  background-color: #111;
  border: 1px solid #000;
  color: #fff;
}
  .productoption_header {
	font-size: 1em;
	padding: 10px 0 10px 0;
	background: #000;
	display: block;
	width: 100%;
	border: 1px solid #222; ;
	text-transform: uppercase;
  }
  .productoption_body {
	overflow: hidden;
	transition: height 0.5s ease-out;
  }
  .productoption_btn {
	display: block;
	color: inherit;
	text-align: center;
	margin: 0 auto;
	border: none;
	background: transparent;
	text-decoration: none;
	padding: 10px 0 10px 0;
	font-size: 1em;
  }

canvas.scene:focus {
  outline: none;
}

.collapsible-additional-info {
  width: 100%;
  border: 1px solid #e8e8e8;
  padding-top: 11px;
  font-weight: 400;
  color: #000;
  text-align: center;
  cursor: pointer;
  position: relative;
  background: 0 0;
  margin-block-start: 2.33em;
  margin-block-end: 2.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  outline: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-style: normal;
  display: block;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  line-height: 2em;
  min-height: 45px;
  padding: 0;
  margin: 0 0 5px;
}

.additional-info-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: #f1f1f1;
  padding-left: 26px;
  margin: 0;
  background: 0 0;
  border: 0;
  vertical-align: baseline;
  outline: 0;
  visibility: visible;
  font-family: 'Playfair Display', sans-serif;
  color: #606060;
  font-size: 12px;
  font-weight: 400;
  line-height: 26px;
}

.additional-info {
  padding: 20px 0 25px;
  display: block;
  background: 0 0;
  border: 0;
  margin: 0;
  outline: 0;
  visibility: visible;
  font-family: 'Playfair Display', sans-serif;
  color: #606060;
  font-size: 12px;
  font-weight: 400;
  line-height: 26px;
}

.my-icon:after {
  display:block;
  content: '';
  border-bottom: solid 3px #3aae57;
  transform: scaleX(0);
  transition: transform .2s ease-in-out;
}
.my-icon:hover:after {
   transform: scaleX(1);
   }